






























import { Component, Vue, Watch } from 'vue-property-decorator';
import PublicPage from '@/components/swine/layout/PublicPage.vue';
import AuthenticationForm from '@/components/general/layout/AuthenticationForm.vue';
import ForgetPasswordStore from '@/store/swine/login/forget-password.store';
import { validateEmail } from '@/utils/validation';

@Component({
  components: {
    PublicPage,
    AuthenticationForm,
  },
})
export default class ForgotPassword extends Vue {
  title = 'Forgot Password';
  email = '';
  isInvalidEmail = false;
  errorMessage = 'Email does not exist';
  forgetPasswordStore = ForgetPasswordStore;
  isButtonDisabled = false;

  created() {
    this.disableButton();
  }

  @Watch('email')
  disableButton() {
    if (validateEmail(this.email)) {
      this.isButtonDisabled = false;
    } else {
      this.isButtonDisabled = true;
    }
  }

  submitForgotPasswordForm() {
    this.forgetPasswordStore.submitForgetPasswordDetails(this.email);
  }
}
